import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { WordService } from './services/word.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  removedWordsCount = 50;
  removedWords = [];

  constructor(public wordService: WordService,
    private alertController: AlertController) { }


  async approveDisapprovedItem(item: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Approve For Disapproved Item!',
      message: 'Would you like to move <strong>' + item + '</strong> to approved words?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
          id: 'cancel-button',
          handler: (blah) => {
          }
        }, {
          text: 'Yes',
          cssClass: 'confirm-button',
          id: 'confirm-button',
          handler: () => {
            this.wordService.removeFromRemovedWords(item);
            this.wordService.approveRemovedItem(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async disapproveApprovedItem(item: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Approve For Disapproved Item!',
      message: 'Would you like to move <strong>' + item + '</strong> to approved words?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
          id: 'cancel-button',
          handler: (blah) => {
          }
        }, {
          text: 'Yes',
          cssClass: 'confirm-button',
          id: 'confirm-button',
          handler: () => {
            this.wordService.removeFromApprovedWords(item);
            this.wordService.removeApprovedItem(item);
          }
        }
      ]
    });

    await alert.present();
   }
}
