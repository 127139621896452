<ion-app>
  <ion-split-pane contentId="main">
    <!--  the side menu  -->
    <ion-menu side="start" contentId="main" style="--side-width: 380px;">
      <ion-header [translucent]="true" mode="ios">
        <ion-toolbar>
          <ion-title mode="ios">Disapproved Words</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list style="background: transparent;">
          <ion-item *ngFor="let item of this.wordService.removedWords">
            {{item}}
            <ion-button slot="end" color="success" fill="clear" (click)="approveDisapprovedItem(item)">
              Approve
            </ion-button>
          </ion-item>
          <ion-button expand="block" (click)="this.wordService.loadRemovedWords()">Load More</ion-button>
        </ion-list>
      </ion-content>
    </ion-menu>

    <!-- the main content -->
    <ion-router-outlet id="main"></ion-router-outlet>

    <ion-menu side="end" contentId="main" style="--side-width: 380px;">
      <ion-header [translucent]="true" mode="ios">
        <ion-toolbar>
          <ion-title mode="ios">Approved Words</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list style="background: transparent;">
          <ion-item *ngFor="let item of this.wordService.approvedWords">
            {{item}}
            <ion-button slot="end" color="danger" fill="clear" (click)="disapproveApprovedItem(item)">
              Disapprove
            </ion-button>
          </ion-item>
          <ion-button expand="block" (click)="this.wordService.loadApprovedWords()">Load More</ion-button>
        </ion-list>
      </ion-content>
    </ion-menu>
  </ion-split-pane>
</ion-app>
