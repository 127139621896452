/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Storage } from '@capacitor/storage';
import * as allWords from 'src/app/data/all-words/en.json';

const APPROVED_KEY = 'approvedWords';
const REMOVED_KEY = 'removedWords';

const ITEM_SIZE = 50;

@Injectable({ providedIn: 'root' })
export class WordService {

  approvedWords = [];
  removedWords = [];
  pendingWords = [];

  private addedApprovedItemsCount = 0;
  private addedRemovedItemsCount = 0;

  private sentApprovedItemsPhase = 0;
  private sentRemovedItemsPhase = 0;
  private sentPendingItemsPhase = 0;

  private allApprovedWords: string[] = [];
  private allRemovedWords: string[] = [];
  private allPendingWords: string[] = [];

  constructor(private sanitizer: DomSanitizer) {
    this.loadAllApprovedWords();
    this.loadAllRemovedWords();
    // this.fillPendings();
    // this.loadApprovedWords();
    // this.loadRemovedWords();
    // this.loadPendingWords();
    //console.log(allWords['default'].length);
  }

  approve(item: string) {
    this.allPendingWords.splice(this.allPendingWords.indexOf(item), 1);
    this.pendingWords.splice(this.pendingWords.indexOf(item), 1);
    this.approvedWords = [item, ...this.approvedWords];
    this.addToApprovedWords(item);
    this.addedApprovedItemsCount++;

    if (this.pendingWords.length < 20) {
      this.loadPendingWords();
    }
  }

  remove(item: string) {
    this.allPendingWords.splice(this.allPendingWords.indexOf(item), 1);
    this.pendingWords.splice(this.pendingWords.indexOf(item), 1);
    this.removedWords = [item, ...this.removedWords];
    this.addToRemovedWords(item);
    this.addedRemovedItemsCount++;

    if (this.pendingWords.length < 20) {
      this.loadPendingWords();
    }
  }

  approveRemovedItem(item: string) {
    this.approvedWords = [item, ...this.approvedWords];
    this.addToApprovedWords(item);
    this.addedApprovedItemsCount++;

    if (this.pendingWords.length < 20) {
      this.loadPendingWords();
    }
  }

  removeApprovedItem(item: string) {
    this.removedWords = [item, ...this.removedWords];
    this.addToRemovedWords(item);
    this.addedRemovedItemsCount++;

    if (this.pendingWords.length < 20) {
      this.loadPendingWords();
    }
  }

  loadRemovedWords() {
    console.log(this.allRemovedWords);
    if (((ITEM_SIZE * this.sentRemovedItemsPhase) + this.addedRemovedItemsCount) <= this.allRemovedWords.length) {
      let upperLimit = ((ITEM_SIZE * (this.sentRemovedItemsPhase + 1)) + this.addedRemovedItemsCount);
      if (this.allRemovedWords.length < ((ITEM_SIZE * (this.sentRemovedItemsPhase + 1)) + this.addedRemovedItemsCount)) {
        upperLimit = this.allRemovedWords.length;
      }


      for (let i = ((ITEM_SIZE * this.sentRemovedItemsPhase) + this.addedRemovedItemsCount); i < upperLimit; i++) {
        //this.removedWords = [this.allRemovedWords[i], ...this.removedWords];
        this.removedWords.push(this.allRemovedWords[i]);
        console.log(this.allRemovedWords[i]);

      }
      this.sentRemovedItemsPhase++;
    }

  }

  loadApprovedWords() {

    console.log(this.allApprovedWords);
    if (((ITEM_SIZE * this.sentRemovedItemsPhase) + this.addedRemovedItemsCount) <= this.allApprovedWords.length) {
      let upperLimit = ((ITEM_SIZE * (this.sentApprovedItemsPhase + 1)) + this.addedApprovedItemsCount);
      if (this.allApprovedWords.length < ((ITEM_SIZE * (this.sentApprovedItemsPhase + 1)) + this.addedApprovedItemsCount)) {
        upperLimit = this.allApprovedWords.length;
      }
      for (let i = ((ITEM_SIZE * this.sentApprovedItemsPhase) + this.addedApprovedItemsCount); i < upperLimit; i++) {
        //this.removedWords = [this.allRemovedWords[i], ...this.removedWords];
        this.approvedWords.push(this.allApprovedWords[i]);
      }
      this.sentApprovedItemsPhase++;
    }
  }

  loadPendingWords() {
    for (let i = ((ITEM_SIZE * this.sentPendingItemsPhase)); i < ((ITEM_SIZE * (this.sentPendingItemsPhase + 1))); i++) {
      this.pendingWords.push(this.allPendingWords[i]);
      // console.log(this.allPendingWords[i]);
    }
    this.sentPendingItemsPhase++;
  }

  exportApproveds() {
    const theJSON = JSON.stringify(this.allApprovedWords);
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=UTF-8,' + encodeURIComponent(theJSON));
    element.setAttribute('download', 'approved-words.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  async removeFromApprovedWords(word: string) {
    const index = this.approvedWords.indexOf(word);
    if (index < this.addedApprovedItemsCount) {
      this.addedApprovedItemsCount--;
    }

    this.approvedWords.splice(index, 1);
    this.allApprovedWords.splice(this.allApprovedWords.indexOf(word), 1);
    await Storage.set({ key: APPROVED_KEY, value: JSON.stringify(this.allApprovedWords) });
  }

  async removeFromRemovedWords(word: string) {
    const index = this.removedWords.indexOf(word);
    if (index < this.addedRemovedItemsCount) {
      this.addedRemovedItemsCount--;
    }

    this.removedWords.splice(index, 1);
    this.allRemovedWords.splice(this.allRemovedWords.indexOf(word), 1);
    await Storage.set({ key: REMOVED_KEY, value: JSON.stringify(this.allRemovedWords) });
  }

  private async loadAllApprovedWords() {
    const { value } = await Storage.get({ key: APPROVED_KEY });
    if (value !== null) {
      this.allApprovedWords = JSON.parse(value);
      this.loadApprovedWords();
    }
  }

  private async loadAllRemovedWords() {
    const { value } = await Storage.get({ key: REMOVED_KEY });
    if (value !== null) {
      this.allRemovedWords = JSON.parse(value);
      this.loadRemovedWords();
    }

    this.fillPendings();
  }

  private async addToRemovedWords(item: string) {
    this.allRemovedWords = [item, ...this.allRemovedWords];
    // this.removedWords.push(item);
    await Storage.set({ key: REMOVED_KEY, value: JSON.stringify(this.allRemovedWords) });
  }

  private async addToApprovedWords(item: string) {
    this.allApprovedWords = [item, ...this.allApprovedWords];
    // this.approvedWords.push(item);
    await Storage.set({ key: APPROVED_KEY, value: JSON.stringify(this.allApprovedWords) });
  }

  private fillPendings() {
    allWords['default'].forEach(w => {
      const approved = this.allApprovedWords.find(a => a === w);
      const removed = this.allRemovedWords.find(a => a === w);

      if (approved === undefined && removed === undefined) {
        this.allPendingWords.push(w);
      }
    });
    this.loadPendingWords();

  }
}
